import React from "react"
import Helmet from "react-helmet"
import Appbar from "../components/appbar"
import Footer from "../components/footer"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import DoughnutChart from "../components/doughnut-chart"

export const fluidImage = graphql`
    fragment fluidImage on File {
        childImageSharp {
            fluid(maxWidth: 250) {
                ...GatsbyImageSharpFluid
            }
        }
    }
`

function PreviousMugs() {

  const data = useStaticQuery(graphql`
      query {
          imageOne: file(relativePath: { eq: "mug1.jpeg" }) {
              ...fluidImage
          }
          imageTwo: file(relativePath: { eq: "mug2.jpg" }) {
              ...fluidImage
          }
          imageThree: file(relativePath: { eq: "mug3.jpg" }) {
              ...fluidImage
          }
          imageFour: file(relativePath: { eq: "mug4.jpg" }) {
              ...fluidImage
          }
          imageFive: file(relativePath: { eq: "mug5.jpg" }) {
              ...fluidImage
          }
          imageSix: file(relativePath: { eq: "mug6.jpeg" }) {
              ...fluidImage
          }
      }
  `)

  const previousMugs = [
    {
      date: "Saturday 11th April 2020",
      img: data.imageSix.childImageSharp.fluid,
      summary: "A superhero mug for a superhero.. This mug was not used for hot drinks, but rather as a pen holder on a computer desk. This mug was sponsored by the mother J Linares.",
      doughnutData: {
        labels: [
          "Rob",
          "Chris Noon",
          "Clark Kent",
          "DaD",
          "Mum",
          "Pepe",
          "Cyril Jones",
        ],
        datasets: [{
          data: [10, 1, 1, 8, 8, 8, 8],
          backgroundColor: [
            "#BFB1D5",
            "#ADDDCF",
            "#ABE1FD",
            "#FED1BE",
            "#F0E0A2",
            "#E8E7E5",
            "#827E95",
            "#C5B1D5",
            "#B1BFD5",
            "#856EEF",
          ],
        },
        ],
      },
      comments: [
        {
          name: "Rob",
          comment: "James you are a true superhero to us all in the office.",
          bestComment: false,
        },
        {
          name: "Chris Noon",
          comment: "There should only superhero in your life........ the almighty Billy <3.",
          bestComment: false,
        },
        {
          name: "Clark Kent",
          comment: "I must say that I am OUTRAGED. I am a big fan of Superman, think he is great, handsome, not boring at all and definitely the best superhero in the Justice League, I have no affiliation with the guy, people say we look alike but I don't see it, I mean I have glasses!!! To use a mug representing his peoples symbol for hope as a pen holder is a DISGRACE!!! 1/10",
          bestComment: true,
        },
    	{
          name: "DaD",
          comment: "Is it a bird? is it a plane? No its Super Mug!",
          bestComment: false,
        },
        {
          name: "Mum",
          comment: "So as a pen holder.. no more mugs for u",
          bestComment: false,
        },
        {
          name: "Pepe",
          comment: "It Is nice mug for use in all wheather",
          bestComment: false,
        },
        {
          name: "Cyril Jones",
          comment: "Sorry name is Cyril not cumbag, nice mug",
          bestComment: false,
        },
        
      ],
    },
    {
      date: "Friday 10th April 2020",
      img: data.imageFive.childImageSharp.fluid,
      summary: "The Graduation mug was a gift to James after his graduation from Northumbria University. It includes his many hobbies such as badminton and coding, as well as a picture of him with a graduation cap.",
      doughnutData: {
        labels: [
          "James Linares #1 Fan",
          "Mum",
          "Rob",
          "Korohnae Steihom"
        ],
        datasets: [{
          data: [10, 8, 10, 9],
          backgroundColor: [
            "#BFB1D5",
            "#ADDDCF",
            "#ABE1FD",
            "#FED1BE",
            "#F0E0A2",
            "#E8E7E5",
            "#827E95",
            "#C5B1D5",
            "#B1BFD5",
            "#856EEF",
          ],
        },
        ],
      },
      comments: [
        {
          name: "James Linares #1 Fan",
          comment: "As Aristotle said, 'Excellence is a habit.' I would say furthermore that excellence is made constant through the feeling that comes right after one has completed a work which is undeniably awe-inspiring (this mug). 10/10 inspirational mug. At the end of the day the difference between ordinary and extraordinary is James Linares.",
          bestComment: true,
        },
        {
          name: "Mum",
          comment: "A very proud moment.",
          bestComment: false,
        },
        {
          name: "Rob",
          comment: "What a beautiful mug.",
          bestComment: false,
        },
        {
          name: "Korohnae Steihom",
          comment: "halo. i from Wuhan China. Veri nice mug. I like. R u selling? Stay home x",
          bestComment: false,
        }
      ],
    },
    {
      date: "Thursday 9th April 2020",
      img: data.imageFour.childImageSharp.fluid,
      summary: "The Angry Birds mug suddenly appeared in the cupboard. We have no knowledge on how this has come into our possession.",
      doughnutData: {
        labels: [
          "Brandon",
          "Rob",
          "Chris Noon",
          "Llanito Shakespeare",
          "Freddy Lima",
          "Birdsarentreal",
          "Sarah",
          "Alex",
          "Babe",
        ],
        datasets: [{
          data: [5, 1, 7, 4, 10, 9, 7, 6, 4],
          backgroundColor: [
            "#BFB1D5",
            "#ADDDCF",
            "#ABE1FD",
            "#FED1BE",
            "#F0E0A2",
            "#E8E7E5",
            "#827E95",
            "#C5B1D5",
            "#B1BFD5",
            "#856EEF",
          ],
        },
        ],
      },
      comments: [
        {
          name: "Brandon",
          comment: "N/A",
          bestComment: false,
        },
        {
          name: "Rob",
          comment: "Racist.",
          bestComment: false,
        },
        {
          name: "Chris Noon",
          comment: "If tez was a bird he would be an angry bird - but a special one #Tard.",
          bestComment: false,
        },
        {
          name: "Llanito Shakespeare",
          comment: "Noow this is un chiste all about how I rated a mug from James the clown, and id like to take a minuto just sit down there, I'll tell you how to rate this mug by the llanito shakespeare... tu, tutu, tu tu tuuuu tu.... Innnnn Givrolta born and raised, sipping tea is how I spent most of my days, Sugar, Tea-bag, Water then milk, My technique was extremely silk, When just one day I was given a link, Rate my mug please i'm bored in this pandemic, I gave it one little look and decided right there, this angry mug is pathetic 4/10 I declare...",
          bestComment: true,
        },
        {
          name: "Freddy Lima",
          comment: "Kijo algien a vito una llave por aki? Tiene un logo de ferrari con 5 llave. Si algien la ve darme un toke a mi mobile plzzzz 54078889",
          bestComment: false,
        },
        {
          name: "Birdsarentreal",
          comment: "It’s only good if you’re using it post-ironically",
          bestComment: false,
        },
        {
          name: "Sarah",
          comment: "A classic ghost mug.",
          bestComment: false,
        },
        {
          name: "Alex",
          comment: "Maybe your love of angry birds is not the only closet secret ...",
          bestComment: false,
        },
        {
          name: "Babe",
          comment: "Mug is mediocre at best. The collage of colours and ugly fictional creatures are an attack on the senses. It is lack lustre and childish. I have awarded it a 4 because I feel bad for the poor soul who designed it.",
          bestComment: false,
        },

      ],
    },
    {
      date: "Wednesday 8th April 2020",
      img: data.imageThree.childImageSharp.fluid,
      summary: "The Frozen mug was received as a glorious gift during the Easter period many moons ago, during the hype of the Frozen pandemic" +
        " (not to be confused with the COVID19 pandemic). This mug was" +
        " sponsored by C.A Hammond.",
      doughnutData: {
        labels: [
          "Rob",
          "Chris Noon",
          "Carole Baskin",
          "Alex",
          "Kairon",
          "Sarah",
          "Bex",
          "Reginald Horace Smith",
          "Vince",
          "Dad",
        ],
        datasets: [{
          data: [10, 2, 3, 8, 9, 1, 7, 4, 4, 8],
          backgroundColor: [
            "#BFB1D5",
            "#ADDDCF",
            "#ABE1FD",
            "#FED1BE",
            "#F0E0A2",
            "#E8E7E5",
            "#827E95",
            "#C5B1D5",
            "#B1BFD5",
            "#856EEF",
          ],
        },
        ],
      },
      comments: [
        {
          name: "Rob",
          comment: "So how then I caressed your face The beauty of which felled from grace And etched the sight of those ice blue eyes The ones that haunt me and make me cry And then see you ever so near To kiss my lips with such care and dear And left me wanting all the more For the man I wait for, the man I adore.",
          bestComment: false,
        },
        {
          name: "Chris Noon",
          comment: "Jesus when ru gonna get a proper job and stop working at clown town?",
          bestComment: false,
        },
        {
          name: "Carole Baskin",
          comment: "Hey all you cool cats and kittens its Carole at big cat rescue. For today's daily update I will be rating this mug. This mug looks like something out of Joe Exotics slaughterhouse, 3/10 wouldn't feed this to my tigers.",
          bestComment: true,
        },
        {
          name: "Alex",
          comment: "I think you need to let it go and accept that YOUR mugs are inferior ...",
          bestComment: false,
        },
        {
          name: "Kairon",
          comment: "Looks very fun, super jealous! :)",
          bestComment: false,
        },
        {
          name: "Sarah",
          comment: "Ew.",
          bestComment: false,
        },
        {
          name: "Bex",
          comment: "Love Olaf.. but cannot forgive that colour :(",
          bestComment: false,
        },
        {
          name: "Reginald Horace Smith",
          comment: "Even though Frozen is a wonderful motion picture i believe this is lackluster mug. This is due to the fact that Olaf is quite an ugly snowman if you ask me. I know you should not judge by looks but the fella looks like he has a few missing brain cells and was definately dropped as a baby (snowball). Im sure he is a nice chap but his face should not be on a mug period.",
          bestComment: false,
        },
        {
          name: "Vince",
          comment: "Unlike the previous mugs up for review, this looks to be a lazy piece of work which we are not used to on this website however don't let this mug distract you from the fact that in 1998, The Undertaker threw Mankind off Hell In A Cell, and plummeted 16 ft through an announcer's table",
          bestComment: false,
        },
        {
          name: "Dad",
          comment: "Tooooo pink",
          bestComment: false,
        },

      ],
    },
    {
      date: "Tuesday 7th April 2020",
      img: data.imageTwo.childImageSharp.fluid,
      summary: "The J Tartan Mug was purchased in Dunnes Stores in Spain. A matching mug with a 'C' was" +
        " also bought for Charlene. The gold handle adds an extra shine to the mug, making it one of the most elegant.",
      doughnutData: {
        labels: [
          "Dale",
          "Joe Exotic",
          "Papi Jax",
          "Sarah",
          "Rob",
          "Dad",
          "John Joshua",
          "Captain Gibraltar",
          "Alex",
          "Babe",
        ],
        datasets: [{
          data: [9, 10, 4, 6, 10, 8, 1, 8, 3, 10],
          backgroundColor: [
            "#BFB1D5",
            "#ADDDCF",
            "#ABE1FD",
            "#FED1BE",
            "#F0E0A2",
            "#E8E7E5",
            "#827E95",
            "#C5B1D5",
            "#B1BFD5",
            "#856EEF",
          ],
        },
        ],
      },
      comments: [
        {
          name: "Dale",
          comment: "Love you man",
          bestComment: false,
        },
        {
          name: "Joe Exotic",
          comment: "This is one mighty fine mug, i'm glad Carol F... Baskin won't get her paws on this!",
          bestComment: false,
        },
        {
          name: "Papi Jax",
          comment: "Nice mug, but why are you not working overtime? Your fired!",
          bestComment: false,
        },
        {
          name: "Sarah",
          comment: "nicer than the other one but like, very viejo of you. You got an extra point for matching the background",
          bestComment: false,
        },
        {
          name: "Rob",
          comment: "Elegance personified, Grace everlasting, I can feel my soul fluttering, Even at the anticipation of your touch, Mug most elegant",
          bestComment: false,
        },
        {
          name: "Dad",
          comment: "Classic model",
          bestComment: false,
        },
        {
          name: "John Joshua",
          comment: "Not impressed",
          bestComment: false,
        },
        {
          name: "Captain Gibraltar",
          comment: "Solid use of colour, the towel adds a great touch to make this a more aesthetically pleasing image. The gold handle creates a touch of elegance not seen in many mugs. Simple but yet a great mug for any of you J's out there.",
          bestComment: true,
        },
        {
          name: "Alex",
          comment: "Less camp than",
          bestComment: false,
        },
        {
          name: "Babe",
          comment: "An artistic masterpiece. I'll start the bid at £1 million.",
          bestComment: false,
        },
      ],
    },
    {
      date: "Monday 6th April 2020",
      img: data.imageOne.childImageSharp.fluid,
      summary: "The Jack Wills Mug was purchased when James was in university. It came with some matching socks which sealed the deal. One of the" +
        " bigger mugs in his collection, this is used for morning coffee as it can hold a larger amount of liquid.",
      doughnutData:
        {
          labels: [
            "Alex",
            "Rob",
            "Dale",
            "Dad",
            "Sarah",
            "Babe",
          ],
          datasets:
            [{
              data: [0, 10, 10, 8, 4, 9],
              backgroundColor: [
                "#BFB1D5",
                "#ADDDCF",
                "#ABE1FD",
                "#FED1BE",
                "#F0E0A2",
                "#E8E7E5",
              ],
            },
            ],
        }
      ,
      comments: [
        {
          name: "Alex",
          comment: "Worst mug yet",
          bestComment: false,
        },
        {
          name: "Rob",
          comment: "Great mug and great story, we love James and his crazy adventures!",
          bestComment: false,
        },
        {
          name: "Dale",
          comment: "Love you",
          bestComment: false,
        },
        {
          name: "Dad",
          comment: "You are the mug for buying at JW :-)",
          bestComment: true,
        },
        {
          name: "Sarah",
          comment: "It’s too Christmassy. It’s April, no one is happy",
          bestComment: false,
        },
        {
          name: "Babe",
          comment: "A lovely mug, just like yourself",
          bestComment: false,
        },
      ],
    }]

  return (
    <div>
      <Appbar/>
      <Helmet>
        <title>Previous Mugs | Rate Out of 10</title>
      </Helmet>
      <div className="container">
        {
          previousMugs.map(mug => (
            <div className="pb-16 border-bottom">
              <div className="row col">
                <h2 className="mx-auto mt-4 mb-8 font-weight-bold text-5xl text-orange-600 uppercase">
                  {mug.date}
                </h2>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <Img className="mx-auto"
                       fluid={mug.img}
                       alt="My amazing mug"
                  />
                </div>
                <div className="col-md-9 flex content-around flex-wrap">
                  <DoughnutChart data={mug.doughnutData}/>
                  <div className="mx-auto uppercase font-bold text-orange-600">
                    Average: {(mug.doughnutData.datasets[0].data.reduce((a, b) => a + b, 0) / mug.doughnutData.datasets[0].data.length).toFixed(2)}
                  </div>
                </div>
              </div>
              <div className="row col">
                <p className="mx-auto mt-4 text-orange-400">
                  {mug.summary}
                </p>
              </div>
              <div className="row col">
                <h2 className="mx-auto mt-4 mb-8 font-weight-bold text-2xl text-orange-600 uppercase">
                  Comments
                </h2>
              </div>
              <div className="row">
                <div className="col">
                  {
                    mug.comments.map(com => (
                      <div>
                        <span className="text-orange-400">{com.name}:</span> {com.comment} {com.bestComment ?
                        <span className="inline-block bg-orange-200 text-orange-800 text-xs px-2 rounded-full uppercase font-semibold tracking-wide">!!Best Comment Award!!</span> : ""}
                        <br/>
                      </div>
                    ))
                  }
                </div>
              </div>
              <hr/>
            </div>
          ))
        }
      </div>
      <Footer/>
    </div>
  )

}

export default PreviousMugs
